.right {
    text-align: end;
}

body {}

.desktopOnly {}

@media (max-width: 600px) {
    .desktopOnly {
        display: none;
    }
}